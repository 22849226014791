<template>
    <div class="pdf-viewer">
        <div class="pdf-container pos-relative" ref="pdf">
            <j-content-loader v-if="!loaded" message="Chargement du document"></j-content-loader>
            <iframe v-on:load="iframeLoad()" ref="embed" :key="src + random"  frameBorder="0" scrolling="auto" :src="srcViewer"  ></iframe>
        </div>
    </div>
</template>

<style lang="scss">
    .pdf-viewer{
        overflow: hidden;
        border-radius: 1em;
        .pdf-container{
            max-width: 100%;
            width:calc(100vw - 40px - 16px);
            overflow: auto;
            background: #D1D1D1;
            padding-top: 0.5em;
            >*{
                transition: all ease 0.3s;
            }
            iframe{
                width: 100%;
                height: 80vh;
                z-index: 1;
                @include phone {
                    height: 100vh;
                }
            }
        }
    }
</style>

<script>
import 'bootstrap-css-only/css/bootstrap.min.css'

export default {
  name: 'pdf-viewer',
  props:['src'],
  data(){
      return {
          loaded : false,
          random : Math.floor(Math.random()*1000),
          reloadInt : null
      }
  },
  watch:{
  },
  mounted () {
      this.reloadInt = setInterval(this.reloadIframe, 2000)
  },
  methods:{
      reloadIframe () {
          if (!this.loaded && this.$refs && this.$refs.embed.contentDocument && this.$refs.embed.contentDocument.URL === "about:blank") this.random = Math.floor(Math.random()*1000)
          else clearInterval(this.reloadInt)
      },
      iframeLoad () {
          this.loaded = true
      }
  },
  computed: {
      srcViewer () {
          return `https://docs.google.com/viewer?url=${this.src}&embedded=true`
      }
  }
}
</script>
