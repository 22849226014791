<template>
  <div class="optin-input">
      <v-checkbox :disabled="state === 'done'" :class="state" v-model="optin" @change="handleOptin($event)">
          <template v-slot:label>
              <span v-html="content.text"></span>
          </template>
      </v-checkbox>
  </div>
</template>

<script>
export default {
    name: 'optin-input',
    props: ['state','content'],
    data(){
        return {
            optin: false
        }
    },
    methods: {
        handleOptin () {
            const payload = {}
            payload[this.content.inputName] = this.optin
            if (this.state !== 'done' && this.optin ) this.$emit('done', payload)
        }
    }
}
</script>

<style lang="scss">
    .optin-input{
        .v-input{
            padding: 0;
            &.done{
                a{
                    opacity: 0.4;
                }
            }
        }
        label{
            margin: 0
        }
        .v-messages{
            display: none;
        }
    }
</style>