<template>
    <div :class="[absolute && !$vuetify.breakpoint.mobile ? 'logout': 'mx-auto py-3']">
        <v-btn class="secondary j-btn white--text cursor-pointer" @click='logout'>Déconnexion</v-btn>
    </div>
</template>

<script>
// @ is an alias to /src
import {AUTH_LOGOUT } from "@/assets/jobtimise-common/store/actions/auth"

export default {
  name: 'Logout-button',
  props: {
    absolute: {
      type: Boolean,
      required: false,
      default: ()=> {return true;}
    }
  },
  methods : {
      logout: async function() {
        this.$store.dispatch('loading')
        try {
          await this.$store.dispatch(AUTH_LOGOUT)
          this.$router.push('/login')
        } catch (e) {
          this.$store.dispatch('open_dialog',{ message: 'Une erreur est survenue' })
        } finally {
          this.$store.dispatch('stop_loading')
        }
      }
  }
}
</script>
