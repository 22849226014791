<template>
  <span>
    <v-btn fab outlined x-large class="reset-btn" @click='handleClick()'>
        <v-icon>mdi-arrow-right</v-icon>
    </v-btn>
    <p class="mt-1" v-if="text && text.length" >{{text}}</p>
  </span>
</template>

<script>
export default {
    name: 'reset-btn',
    props: ['cb','text'],
    methods: {
      handleClick () {
        this.$emit(this.cb)
      }
    }
}
</script>

<style lang="scss">
  .reset-btn{
    animation: inBottom 0.3s;
    animation-fill-mode: forwards;
    opacity: 0;
    border-color: transparent!important;
    border-width: 2px;
    background: white;
    position: relative;
    &::before, &:after{
      content: '';
      z-index: -1;
      opacity: 1!important;
      position: absolute;
      display: block;
      border-radius: 100%;
      left: -2px!important;
      top: -2px!important;
      width: calc( 100% + 4px );
      height: calc( 100% + 4px );
      background: $mainGradient;
    }
    &:after{
      width: 100%;
      height: 100%;
      left: 0!important;
      top: 0!important;
      background: white;
      transition: all ease 0.3s;
    }
    .v-icon{
      @include textGradient($mainGradient);
    }
    &:hover{
      &::after{
        transform: scale(1.1);
      }
    }
  }
</style>