import Vue from 'vue';
  
  const state = {
    display: false,
    content: {
        message:'',
        alt: ''
    },
    cb: ()=>{
      console.log('Im a callback')
    },
    altCb: ()=>{
      console.log('Im an alt callback')
    }
  }
  
  
  const actions = {
    open_popup: (context,payload)=>{
        context.commit('open_popup',payload);
    },
    close_popup: context=>{
        context.commit('close_popup');
    }
  };
  
  const mutations = {
    open_popup: (state,payload)=>{
        
        const popup ={
            content:{
                message: payload.message || '',
                alt: payload.alt || ''
            },
            cb: payload.cb || '',
            altCb: payload.altCb || ''
        }
        Vue.set(state,'display',true);
        Vue.set(state,'content',popup.content);
        Vue.set(state,'cb',popup.cb);
    },
    close_popup: state=>{
        Vue.set(state,'display',false);
    }
  };
  
  export default {
    state,
    actions,
    mutations
  };
  