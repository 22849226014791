<template>
    <div class="linear-progress">
        <div :style="`width: ${val}%`" class="thumb"></div>
        <div v-if="selfText" :style="`left: ${val}%`" class="cursor self">
            <p>{{selfText}}</p>
        </div>
        <div v-if="modelText" class="cursor model">
            <p class="text-capitalize">{{modelText}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'circular-progress',
    props : ['width', 'selfText', 'modelText'],
    data () {
        return {
            val : 0
        }
    },
    mounted () {
        setTimeout( () => {
            this.val = this.width
        })
    },
    computed: {
    }
}
</script>

<style lang="scss">
.linear-progress{
        $gaugeH : 2rem;
        $gap : 20px;
        $gaugeHresp: 1.5rem;
        width: auto;
        height: $gaugeH;
        background: whitesmoke;
        flex-grow: 1;
        border-radius: 5px;
        position: relative;
        @include phone {
            height: $gaugeHresp;
        }
        .thumb{
            border-radius: 5px;
            height: 100%;
            background: $mainGradient;
            transition: all ease 1.5s;
        }
        .cursor{
            position: absolute;
            left: 100%;
            bottom: 0;
            height: $gaugeH;
            transition: all ease 1.5s;
            @include phone {
                height: $gaugeHresp;
            }
            p{
                margin: 0;
                font-size: 0.7em;
                line-height: 1;
                position: absolute;
                right: 100%;
                bottom: calc( 100% + #{ $gap });
                color: $grey;
                width: 7rem;
                text-align: right;
                padding-right: 3px;
            }
            &::after{
                content: '';
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                height: calc( 100% + #{ $gap } );
                border-left: $grey dashed 1px;
            }
            &.self{
                left: 60%;
                p{
                    color: $purple;
                }
                &::after{
                    border-color: $purple;
                }
            }
            &.model{
                p{
                    bottom: calc( 100% + #{ $gap } + 1rem);
                }
            }
        }
}
</style>