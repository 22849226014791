<template>
  <div>
    <p v-if="videoSelected && videoSelected.title" class="ma-0 video-title">{{videoSelected.title}}</p>
    <div class="d-flex flex-column flex-md-row videos-playlist"> 
        <div :class="[isPlaylist ? 'wi-70 wi-sm-100 mx-0 mx-md-2' : 'wi-90 mx-auto pb-2', ' container-nav mb-2 mb-md-0']">
          <div class="video-selected">
            <span v-if="videoSelected">
                <j-youtube-embed @end="nextVideo()" v-if="videoSelected.type === 'youtube'" :video="videoSelected" :ref="`video${videoSelected.id}`"></j-youtube-embed>
                <j-vimeo-embed @end="nextVideo()" v-if="videoSelected.type === 'vimeo'" :video="videoSelected.link" :ref="`video${videoSelected.id}`"></j-vimeo-embed>
            </span>
          </div>
          <span v-if="!$vuetify.breakpoint.mobile && playlistIndex >= 1" class="nav-video nav-left d-flex align-center justify-center">
            <span class="arrow mx-2" @click="playlistIndex = playlistIndex - 1"><v-icon>mdi-chevron-left</v-icon></span>
          </span>
          <span v-if="!$vuetify.breakpoint.mobile && playlistIndex !== (cached_videos.length - 1)" class="nav-video nav-right d-flex align-center justify-center">
            <span class="arrow mx-2" @click="playlistIndex = playlistIndex + 1"><v-icon>mdi-chevron-right</v-icon></span>
          </span>
        </div>
        <div v-if="isPlaylist" class="wi-30 wi-sm-100 scroll-playlist">
          <span v-for="(video,index) in cached_videos" :key="video.id+index">
            <div @click="preview(index)" :class="[playlistIndex === index && 'video-active', 'video-item d-flex']">
              <div class="wi-15 d-flex align-items-center justify-center">
                <span class="px-2 h6 font-weight-bold">{{index + 1}}</span>
              </div>
              <div class="wi-85 d-flex py-2">
                <div :class="[video.title ? 'wi-50' : 'wi-100 px-2']">
                  <j-video-thumbnail :class="[playlistIndex !== index && 'inactive']" :video="video"></j-video-thumbnail>
                </div>
                <p v-if="video.title" class="ma-0 wi-50 px-2 thumb-title">{{video.title}}</p>
              </div>
            </div>
          </span>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'video-carousel',
  components: {
  },
  data(){
    return {
        playlistIndex: 0,
        isPlaylist: false
    }
  },
  props:['videos'],
  created(){
    if(this.cached_videos.length > 1) this.isPlaylist = true
  },
  computed:{
      cached_videos(){
          if(!this.videos.length) return []
          return this.videos.map(v=>{
              return this.get_video_url(v)
          }).filter(e=>e!=null)
      },
      videoSelected(){
          return this.cached_videos[this.playlistIndex]
      }
  },
  methods:{
      preview: function(index) {
          this.playlistIndex = index
      },
      get_video_url: function(video_link){
        if(video_link.includes('vimeo')){
            let id = video_link.split('/').pop()
            return {link:`https://player.vimeo.com/video/${id}`,type:"vimeo",id:id}
        }
        else if(video_link.includes('youtube')){
            let id = video_link.split('/').pop()
            return {link:`https://www.youtube.com/embed/${id}`,type:'youtube',id:id}
        }else{
            return null
        }
      },
      changeCarousel(e){
          this.carouselIndex = e
          for(let i = 0; i < this.videos.length; i++){
              this.$refs['video'+i].pause()
          }
      },
      nextVideo:function(){
          if(this.playlistIndex < this.videos.length - 1){
              this.playlistIndex = this.playlistIndex + 1
          }
      }
  }
}
</script>

<style lang="scss">
.video-title {
    word-wrap: break-word;
}
.videos-playlist {
    position: relative!important;
    .container-nav {
        position: relative!important;
        border-radius: 0.8em;
        background: #e1e1e1;
        overflow: hidden!important;
        .video-selected {
            height: auto;
            *,* * {
                width: 100%;
                height: calc(( 65vw - 80px - 80px - 80px ) * 9 / 16);
                @include phone {
                    height: calc(( 100vw - 40px - 16px ) * 9 / 16);
                }
            }
        }
        &:hover .nav-video {
            opacity: 50%;
        }
        .nav-video {
            width: auto!important;
            position: absolute!important;
            height: min-content!important;
            margin: auto;
            top: 0; 
            bottom: 0; 
            opacity: 0;
            transition: all ease 0.3s;
            &.nav-left {
                left: 0;
            }
            &.nav-right {
                right: 0;
            }
            .arrow {
                height: auto!important;
                background: #e1e1e1;
                padding: 0.2em;
                border-radius: 100%;
                &:hover {
                    opacity: 75%;
                    cursor: pointer;
                    .v-icon {
                        font-size: 3.5rem;
                    }
                }
                .v-icon {
                    font-size: 3rem;
                    color: white;
                }
            }
        }
    }
    .scroll-playlist {
        overflow-y: auto;
        border: 1px solid #e1e1e1;
        border-radius: 0.8em;
        height: calc(( 65vw - 80px - 80px - 80px ) * 9 / 16);
        @include phone{
            height: calc(( 100vw - 40px - 16px ) * 9 / 16);
        }
        :not(:last-child) {
            .video-item {
                margin-bottom: 0.2rem;
            }
        }
        .video-item {
            transition: all ease 0.3s;
            .thumb-title {
                word-wrap: break-word;
            }
            &:hover {
                background: #e1e1e1;
                box-shadow: 0px 0px 5px 0px grey;
                cursor: pointer;
                .video-thumbnail {
                    filter: none;
                }
            }
        }
        .video-active {
            background: #e1e1e1;
        }
        .video-thumbnail {
            height: 6rem;
            width: 100%;
            border-radius: 0.8em;
            &.inactive {
                transition: all ease 0.3s;
                filter: grayscale(100);
            }
        }
    }
}
</style>
