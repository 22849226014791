<template>
  <div class="circular-progress">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 102.9 102.9" style="enable-background:new 0 0 102.9 102.9;" xml:space="preserve">
        <defs>
            <linearGradient id="gradient">
                <stop class="stop1" offset="0%"/>
                <stop class="stop2" offset="50%"/>
                <stop class="stop3" offset="100%"/>
            </linearGradient>
        </defs>
        <circle class="back" cx="51.5" cy="51.5" :r="r"/>
        <circle :stroke-dashoffset="pct" class="front" cx="51.5" cy="51.5" :r="r"/>
    </svg>
    <div>
        <p>{{val}}%</p>
        <span>{{sub}}</span>
    </div>
  </div>
</template>

<script>
export default {
    name: 'circular-progress',
    props : ['value', 'sub'],
    data () {
        return {
            r : 40,
            val : 0
        }
    },
    mounted () {
        setTimeout( () => {
            this.val = this.compVal
        })
    },
    computed: {
        c () {
            return Math.PI*(this.r*2)
        },
        pct () {
            return ( ( 100 - this.val + 12 ) / 100 ) * this.c
        },
        compVal () {
            if ( this.value < 0 || isNaN(this.value) || this.value > 100 ) return 100
            else return this.value
        }
    }
}
</script>

<style lang="scss">
.circular-progress{
    height: 4.3rem;
    width: 4.3rem;
    position: relative;
    svg{
        width: 100%;
        height: 100%;
        .front, .back { 
            stroke-width: 0.7rem; 
            fill: transparent;
            transition: all ease-out 1s 0.1s;
            stroke-dasharray: 283;
            stroke: lightgrey;
        }
        .front{
            stroke: url(#gradient);
        }
        .stop3 { stop-color: $primary; }
        .stop2 { stop-color: $secondary;}
        .stop1 { stop-color: $purple;}
    }
    >div{
        position: absolute;
        top : 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        p{
            @include textGradient($flashGradientReverse);
            line-height: 1!important;
            font-size: 1.1em;
            transform: translate(0px,0);
            font-weight: 900;
            margin-bottom: 0;
            @include phone {
                font-size: 1em;
                transform: translate(2px,-2px);
            }
        }
        span{
            position: absolute;
            font-size: 0.6em;
            right: 0;
            top: 100%;
            transform: translate(0,-0.6em);
            color: $textGrey;
        }
    }
}
</style>