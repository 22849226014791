<template>
  <span></span>
</template>

<script>
export default {
    name: 'chat-start',
    created () {
        this.$emit('done')
    }
}
</script>

<style>

</style>