<template>
    <div class="date-picker d-flex">
        <v-text-field class="mr-1" :max="maxDay" :min="minDay" @change="changeDate()" outlined type="number" label="jour" v-model="day"></v-text-field>
        <v-text-field class="mr-1" max="12" :min="minMonth" @change="changeDate()" outlined type="number" label="mois" v-model="month"></v-text-field>
        <v-text-field class="mr-1" :min="minYear" @change="changeDate()" outlined type="number" label="année" v-model="year"></v-text-field>
    </div>
</template>

<script>


export default {
  name: 'date-picker',
  props:['date'],
  data: () => ({
      day:1,
      month:1,
      year: 2021,
      minYear:new Date().getFullYear()
  }),
  created(){
      if(typeof this.date != 'undefined'){
          this.day = this.date.getDate()
          this.month = this.date.getMonth() + 1
          this.year = this.date.getFullYear()
      }
  },
  computed:{
      minMonth(){
          const current = new Date()
          if(parseInt(this.year) === current.getFullYear())return current.getMonth() + 1
          else return 1
      },
      minDay(){
          const current = new Date()
          if(parseInt(this.year) === current.getFullYear() && parseInt(this.month) === current.getMonth())return current.getDate()
          else return 1
      },
      maxDay(){
        if([1,3,5,7,8,10,12].includes(parseInt(this.month)))return 31
        else if(parseInt(this.month) === 2 && parseInt(this.year)%4 === 0 )return 29
        else if(parseInt(this.month) === 2)return 28
        else return 30
      }
  },
  methods : {
      changeDate(){
        const current = new Date()

        //control if current year and month < current month
        if(parseInt(this.year) === current.getFullYear() && parseInt(this.month) < current.getMonth() + 1){
            this.month = current.getMonth() + 1
        }
        //control if current year and month and day < current
        if(parseInt(this.year) === current.getFullYear() && parseInt(this.month) === current.getMonth() + 1 && parseInt(this.day) < current.getDate()){
            this.day = current.getDate()
        }
        //controle valid day of month
        if([4,6,9,11].includes(parseInt(this.month)) && parseInt(this.day) > 30 )this.day = 30
        if(parseInt(this.month) === 2 && parseInt(this.year)%4 === 0 && parseInt(this.day) > 29 )this.day= 29
        if(parseInt(this.month) === 2 && parseInt(this.year)%4 > 0 && parseInt(this.day) > 28)this.day = 28
        

        let setDate = new Date(this.year,this.month - 1,this.day)
        this.$emit('change',setDate)
      }
  }
}
</script>
<style lang="scss" >
.date-picker{
    .v-input{
        width: 5rem;
        input{
            text-align: center;
        }
    }
}
</style>

