<template>
    <v-dialog :width='$vuetify.breakpoint.mobile ? "90%" : "50%"' @keydown="handleEsc($event)" :value="$store.getters.displayPasswordPopup" @click:outside.prevent="$store.dispatch('close_password_popup')">
        <v-card>
            <v-toolbar
                color="primary"
                dark
            >Définissez votre mot de passe</v-toolbar>
            <v-card-text class="wi-60 pt-5 ma-auto d-flex flex-column justify-center align-center">
                <p>Entrez votre nouveau mot de passe pour vous connecter plus tard avec l'adresse <strong>{{$store.getters.getEmail}}</strong></p>
                <j-user-form class="wi-80 wi-sm-90 ma-auto"  @focus="errorsPresents = false" @validate="activate" :usage="['password','confirmPassword']" :submitWord="'Définir mon mot de passe'" :button="{color: 'primary', size: 'small'}"></j-user-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'password-popup',
    data () {
        return {
            display: false
        }
    },
    methods : {
        async activate ({ user, recaptcha }){
            this.$store.dispatch('loading')
            try{
                await this.$store.dispatch('set_password', { user, recaptcha })
                this.$store.dispatch('close_password_popup')
            }catch(e){
                this.$store.dispatch('open_dialog',{ message: 'Un erreur est survenue, veuillez réessayer' })
            }finally{
                this.$store.dispatch('stop_loading')
            }
        },
        handleEsc (e) {
            if(e.code === "Escape"){
              this.$store.dispatch('close_password_popup')
            }
        }
    }

}
</script>

<style>

</style>