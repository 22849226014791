<template>
    <div class="stripe-el wi-100 d-flex flex-column elevation-3 main-gradient-pastel">
        <div class="cardInput">
            <div ref="card"></div>
        </div>
        <div class="cardInfos">
        <em>{{stripetitle ? stripetitle : 'Paiement sécurisé via'}} <img src="../../img/stripe/stripe.svg" alt=""></em>
        </div>
        <div class="cardsLogo">
        <img src="../../img/stripe/cb.svg" alt="">
        <img src="../../img/stripe/visa.svg" alt="">
        <img src="../../img/stripe/mastercard.svg" alt="">
        <img src="../../img/stripe/amex.svg" alt="">
        </div>
        <v-btn :disabled="valid ? loading ? 'disabled' : false : 'disabled'" class="j-btn primary mt-2 cardBtn" @click="validateCard()">{{cta}}</v-btn>
    </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'
import { stripePublic } from '@/../config'

export default {
    name: 'card-infos',
    props:['cta','stripetitle'],
    data() {
        return {
            spk: stripePublic,
            stripe: undefined,
            newCard: undefined,
            loading: false
        }
    },
    mounted() {
        this.mountStripe()
    },
    methods: {
        async mountStripe() {
            this.stripe = await loadStripe(this.spk)
            let elements = this.stripe.elements()
            this.newCard = elements.create('card',{hidePostalCode: true})
            this.newCard.mount(this.$refs.card)
        },
        async validateCard() {
            this.$store.dispatch('loading')
            this.loading = true
            try {
                const stripe_token = await this.stripe.createToken(this.newCard)
                this.$emit('validateCard', stripe_token)
            } catch (err) {
                this.$store.dispatch('stop_loading')
                this.$store.dispatch('open_dialog',{title:'Une erreur est survenue',message:`"${err.response.data.message}" Veuillez réessayer.`,button:'Réssayer de remplir les champs'})
            }finally {
                this.loading = false
            }
        }
    },
    computed: {
        valid(){
            return this.newCard ? this.newCard._complete : false
        }
    }
}
</script>

<style lang="scss">
.stripe-el {
    padding: 1em;
    border-radius: 1.5em;
    .cardInfos{
        font-size: 0.6em;
        text-align: right;
        padding-top: 0.3em;
        img{
            height: 1.5em;
            vertical-align: middle;
            margin-left: 0.3em;
        }
    }
    .cardInput {
        background-color: white;
        border-radius: 1em;
        padding: 1em 0.5em;
    }
    .cardsLogo{
        display: flex;
        justify-content: flex-end;
        margin-top: 0.3em;
        img{
            height: 2em;
            margin: 0 0.3em;
            &:last-child{
                margin-right: 0;
            }
        }
    }
    .cardBtn {
        white-space: normal!important;
        display: block!important;
    }
}
</style>
