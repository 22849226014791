<template>
  <div class="chat-full-video">
        <div class="video-container">
            <vimeo-embed :button="true" @end="$emit('done')" :video="video"></vimeo-embed>
        </div>
        <p @click="$emit('done')" >{{text}}</p>
    </div>
</template>

<script>
import VimeoEmbed from '../video/VimeoEmbed.vue'

export default {
    name: 'chat--full-video',
    props: ['video', 'text'],
    components: { VimeoEmbed }
}
</script>

<style lang="scss">
.chat-full-video{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center ;
    justify-content: center;
    @include phone{
        height: 70vh;
    }
    p{
        position: relative;
        margin-top: 2rem;
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
    }
    .video-container{
        >div{
            width: 30vw;
            height: 30vw;
            overflow: hidden;
            border-radius: 100%;
            @include phone{
                width: 70vw;
                height: 70vw;
            }
        }
    }
}
</style>