import Vue from 'vue';

let state = {
  loading: false
};


const actions = {
  loading : context=>{
    context.commit('loading');
  },
  stop_loading: context=>{
    context.commit('stop_loading');
  }
};

const mutations = {
  loading: state=>{
    Vue.set(state,"loading",true);
  },
  stop_loading: state=>{
    Vue.set(state,"loading",false);
  }
};

export default {
  state,
  actions,
  mutations
};
  