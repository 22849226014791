<template>
    <div class="content-loader">
        <div>
            <p v-if="message" class="wi-100 text-center white--text">{{message}}</p>
            <v-progress-linear
                color="secondary"
                indeterminate
                rounded
                height="6"
            ></v-progress-linear>
        </div>
    </div>
</template>

<style lang="scss">
.content-loader{
    position: absolute;
    top:0;
    left: 0;
    height: 100%;
    width:100%;
    background: rgba(black,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    >div{
        width: 30%
    }
}
</style>


<script>
// @ is an alias to /src

export default {
  name: 'ContentLloader',
  props:['message']
}
</script>