<template>
    <div class="wi-100">
        <label :for="id" class="wi-100 d-flex imageDropContainer">
                <img :key="thumbnailURL" v-if="thumbnailURL" :src="thumbnailURL" alt="">
                <b-form-file
                :id="id"
                :class="elClass"
                accept="image/*"
                @change="changeFile"
                :placeholder="placeholder"
                :browse-text="btnText"
                ></b-form-file>
        </label>
    </div>
</template>


<script>
// @ is an alias to /src
import 'bootstrap-css-only/css/bootstrap.min.css'
import Vue from 'vue'
import { FormFilePlugin } from 'bootstrap-vue'
Vue.use(FormFilePlugin)

export default {
  name: 'upload-image',
  data(){
      return {
          imageFile: '',
          id: ''
      }
  },
  props: {
    thumbnail: {
          required : false
    },
    btnText:{
        required: false,
        default: "Parcourir",
        type: String
    },
    placeholder:{
        required: false,
        default: "Chargez une image",
        type: String
    },
    dropPlaceholder:{
        required: false,
        default: "Déposez une image",
        type: String
    },
    elClass:{
        required: false,
        default: "",
        type: String
    }
  },
  created(){
      this.id = Math.floor(Math.random() * 500).toString()
      if(this.thumbnail){
          this.imageFile = this.thumbnail
      }
  },
  computed:{
      thumbnailURL(){
      if(typeof this.imageFile === 'object'){
        return URL.createObjectURL(this.imageFile)
      }else{
        return this.imageFile
      }
    }
  },
  methods : {
      changeFile:function(event){
          const file = event.target.files[0]
          this.imageFile = file
          this.$emit('change', file)
      }
  }
}
</script>
<style lang="scss">
.imageDropContainer{
    height: 200px;
    width: 200px;
    border: lightgrey solid 2px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    background: lightgray;
    img{
        height: calc(100% - 3em);
        width: auto;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%,0);
        z-index: 1;
    }
    &::after{
        content:'Déposez une image';
        width: 80%;
        position: absolute;
        text-align: center;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
    }
}
</style>