<template>
    <div class="d-flex align-center justify-center">
        <j-user-form class="wi-100"   @focus="errorsPresents=false" @validate="login" :usage="['email','password']" :control="false" :submitWord="'Connexion'" ></j-user-form>
        <div class="justify-center mt-5">
            <v-expand-transition>
                <v-alert v-if='errorsPresents' class="red lighten-2 white_">{{errorMessage}}</v-alert>
            </v-expand-transition>
        </div>
    </div>
</template>

<script>
import { AUTH_REQUEST } from "@/assets/jobtimise-common/store/actions/auth";

export default {
    name: 'login-form',
    data: function(){
        return {
            errorsPresents:false,
            errorMessage:"Une erreur est survenue, veuillez réessayer."
        }
    },
    components:{
    },
    methods: {
        login: function (user) {
            this.$store.dispatch(AUTH_REQUEST, user).then(() => {
                this.$emit('logged')
            }).catch(err=>{
                this.errorMessage = err.data.message || "Une erreur est survenue, veuillez réessayer."
                this.errorsPresents = true
            })
        }
    }
}
</script>