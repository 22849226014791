<template>
  <div class="job">
      <div class="wi-100 pl-md-3 d-none">
        <router-link class="back mr-3" to="/results">
            <v-icon>mdi-arrow-left</v-icon>
        </router-link>
        <p class="text-h5 text-uppercase">Découverte du job :</p>
      </div>
      <div v-if="jobContent" class="job-content">
        <div class="wi-100 d-flex align-center job-header ">
            <p class="text-h3 mb-0 mr-md-5 font-weight-bold text-capitalize">{{job.title}}</p>
            <circular-progress :value="Math.floor(job.score)" sub="match"></circular-progress>
        </div>
        <div class="wi-100 d-flex mt-7 flex-column flex-md-row">
            <div v-if="jobContent.content.image.length" class="job-thumbnail wi-40 wi-sm-100 flex-shrink-0" :style="`background-image: url('${jobContent.content.image}')`"></div>
            <p v-if="jobContent.content.description" class="job-descirption ml-md-7 mt-2 mt-md-0 py-2">
                {{jobContent.content.description}}
            </p>
        </div>
        <div class="wi-100 left-border d-flex align-md-center mt-5 flex-column flex-md-row job-stat">
            <div v-if="jobContent.content.offers" class="d-flex flex-column flex-md-row align-center">
                <img src="/images/pictos/job.png" alt="">
                <p v-if="typeof jobContent.content.offers === 'number'" class="ml-2">
                    <strong>{{jobContent.content.offers}} offres d'emplois</strong> dans les 3 derniers mois
                </p>
                <div v-else class="ml-2 d-flex flex-column">
                    <p class="ma-0 body-1">Les <strong>offres d'emplois</strong> dans le dernier mois :</p>
                    <p class="my-0"><v-icon>mdi-chevron-right</v-icon><span class="primary--text font-weight-bold">{{jobContent.content.offers.indeed}}</span> offres sur<img class="ml-2 mb-1 logos-offers" src="/images/jobs/indeed.svg" alt=""></p>
                    <p class="my-0"><v-icon>mdi-chevron-right</v-icon><span class="primary--text font-weight-bold">{{jobContent.content.offers.linkedin}}</span> offres sur<img class="ml-2 mb-1 logos-offers" src="/images/jobs/linkedin.svg" alt=""></p>
                    <p class="my-0"><v-icon>mdi-chevron-right</v-icon><span class="primary--text font-weight-bold">{{jobContent.content.offers.monster}}</span> offres sur<img class="ml-2 mb-1 logos-offers" src="/images/jobs/monster.svg" alt=""></p>
                </div>
            </div>
            <div v-if="jobContent.content.salary" class="d-flex align-center mt-5 mt-md-0">
                <img src="/images/pictos/money.png" alt="">
                <p class="ml-2 font-weight-bold">
                    {{jobContent.content.salary}} /an
                </p>
            </div>
        </div>
        <div v-if="jobContent.content.testimonial.video.length" class="job-video wi-100">
            <div class="wi-100 d-flex flex-column align-center  flex-md-row ">
                <profile-bubble v-if="jobContent.content.testimonial.person.photo.length" :username="''" :image="jobContent.content.testimonial.person.photo"></profile-bubble>
                <p class="text-md-h3 text-h4 font-weight-bold mb-0">{{jobContent.content.testimonial.title}}</p>
            </div>
            <div class="left-border">
                <p>
                    {{jobContent.content.testimonial.intro}}
                </p>
                <vimeo-embed class="ma-auto" :video="jobContent.content.testimonial.video" :button="false"></vimeo-embed>
            </div>
        </div>
        <div class="job-reason">
            <p class="text-md-h3 text-h5 font-weight-bold">Pourquoi ce job est fait pour vous ?</p>
            <div v-if="allShareQualities.length" class="left-border py-5">
                <p class="text-h5 pb-5">Vous avez <strong><span class="text-gradient text-h4 font-weight-bold">{{qualitiesIntersect.length}}</span>/{{allQualities.length}} qualités requises</strong></p>
                <div v-for="(quality, qIndex) in allShareQualities" :key="quality._id" class="quality">
                    <p class="text-capitalize">{{quality.title}}</p>
                    <linear-progress :self-text="qIndex === 0 ? 'Vous' : ''" :model-text="qIndex === 0  ? job.title : ''" :width="quality.share"></linear-progress>
                </div>
            </div>
        </div>
        <engage class="mt-10" title="Votre prochaine étape ?" :content="engageContent" ></engage>
      </div>
  </div>
</template>

<script>
import CircularProgress from '../dataviz/CircularProgress.vue'
import ProfileBubble from '../chat/ProfileBubble.vue'
import VimeoEmbed from '../video/VimeoEmbed.vue'
import LinearProgress from '../dataviz/LinearProgress.vue'
import Engage from '../content/Engage.vue'
import axios from 'axios'
import { apiURL } from '@/../config'

export default {
    name: 'job-description',
    components: {
        CircularProgress,
        ProfileBubble,
        VimeoEmbed,
        LinearProgress,
        Engage
    },
    props : ['job', 'profile'],
    data () {
        return {
            jobContent: null,
            engageContent: [
                {
                    text: `<strong>Contactez gratuitement un de nos coachs</strong> pour établir un plan d'action`,
                    button: {
                    icon :'mdi-calendar',
                    text: 'Prendre rendez-vous',
                    cb: () => { window.open('https://jobtimise.com/rendez-vous','Jobtimise').focus()},
                    big: true
                    }
                }
                // {
                //     text: `Déjà prêt et décidé ? Parcourez nos partenaires de formation pour de venir <strong>Community Manager</strong>`,
                //     button: {
                //     icon :'mdi-account-multiple-plus',
                //     text: 'En savoir plus',
                //     cb: () => { console.log('clicked')  }
                //     }
                // }
            ]
        }
    },
    async created () {
        window.scrollTo(0,0)
        this.$store.dispatch('loading')
        try {
            const response = await axios.get(`${apiURL}public/jobs/${this.job._id}`)
            this.jobContent = response.data.job
        } catch (e) {
            console.log(e)
            this.$store.dispatch('open_dialog',{message:"Une erreur est survenue, veuillez réessayer."})
        } finally {
            setTimeout(() => { return this.$store.dispatch('stop_loading') }, 1000)
        }
    },
    computed: {
        allQualities () {
            if(this.jobContent === null) return []
            return this.jobContent.composition.filter( e=> { return e.quality !== null} )
        },
        allShareQualities () {
            const qualities = []
            for (let q of this.allQualities) {
                qualities.push({ ...q.quality, share: this.qualityShare(q) })
            }
            return qualities.sort( (a,b) => { return b.share - a.share } )

        },
        qualitiesIntersect () {
            if( this.jobContent === null ) return 0
            return this.allQualities.filter( e => { return this.profile.filter( q => { return q.quality === e.quality._id  } ).length > 0  } )
        }
    },
    methods : {
        qualityShare (quality) {
            const profileQ = this.profile.filter( e => { return e.quality === quality.quality._id})
            if (profileQ.length === 0) return 0
            const share = Math.floor(profileQ[0].score / quality.score * 100)
            return share > 100 ? 99 : Math.max( share - 1, 5 )
        }
    }
}
</script>

<style lang="scss">
  .job{
    min-height: 100vh;
    overflow: hidden;
    padding: 2rem;
    padding-top: 5rem;
    background: white;
    text-align: left;
    padding-bottom: 5rem;
    @include phone{
        padding: 1rem;
        min-height: 110vh;
    }
    .job-content {
        .logos-offers {
            height: 1rem;
        }
    }
    .circular-progress{
        @include phone{
            position: absolute;
            right: 0;
            top: 0%;
            width: 4rem;
            height: 4rem;
        }
    }
    .back{
        text-decoration: none;
    }
    .left-border{
        border-left: whitesmoke solid 10px;
        padding-left: 5rem;
        @include phone {
            padding-left: 1rem;
        }
    }
    &-content{
        padding: 0 5rem;
        @include phone {
            padding: 5rem 1rem 0;
        }
    }
    &-header{
        position : relative;
        >p{
            @include phone {
                width: calc(100% - 4.3rem);
                margin-right: 0;
                overflow-wrap: break-word;
            }
        }
    }
    &-thumbnail{
        min-height: 20rem;
        max-height: 25rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.8;
        filter:brightness(1.1);
        @include phone {
            width: 100%;
            height: 20rem;
        }
    }
    &-stat{
        padding: 1rem 0 1rem;
        >div:first-child{
            margin-right: 5rem;
            @include phone {
                margin-right: 0;
            }
        }
        img{
            height: 4rem;
            @include phone {
                transform: scale(0.8);
            }
        }
        p{
            font-size: 1.3rem;
            margin-bottom: 0;
        }
    }
    &-video{
        margin-top: 8rem;
        .profile-bubble{
            @include noPhone {
                transform: translate( calc( -50% + 5px ), 0) scale(1.1);
            }
            @include phone {
                height: 10rem;
                width: 10rem;
                margin-bottom: 1rem;
            }
        }
        .left-border{
            margin-top: 0.8rem;
        }
        iframe{
            margin-top: 2rem;
            height: 40rem;
            @include phone {
                height: 15rem;
                width: 100%;
            }
        }
    }
    &-reason{
        margin-top: 8rem;
        .quality{
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 2rem;
            >p{
                width: 15%;
                flex-grow: 0;
                margin-bottom: 0;
                @include phone {
                    font-size: 0.8em;
                    width: 5rem;
                }
            }
        }
    }

  }
</style>
