<template>
  <div>
    <div class="d-flex">
        <p class="h4 primary--text">Formations & Parcours :</p>
        <p @click="checkAll()" class="text-decoration-underline cursor-pointer text-primary ml-2">Tout cocher</p>
        <p @click="uncheckAll()" class="text-decoration-underline cursor-pointer ml-2">Tout décocher</p>
    </div>
    <div class="courseChoice">
        <div>
            <div class="d-flex">
                <p class="h4 primary--text">Les formations :</p>
                <p @click="checkAll(courses)" class="text-decoration-underline cursor-pointer text-primary ml-2">Tout cocher</p>
                <p @click="uncheckAll(journeys)" class="text-decoration-underline cursor-pointer ml-2 ">Tout décocher</p>
            </div>
            <label  v-for="course in courses" :key="course._id">
                <input type="checkbox" :checked="isCourseChecked(course._id)" @change="checkCourse(course._id,$event.target.checked)"  >{{course.title}}
            </label>
        </div>
        <div class="ml-10">
            <div class="d-flex">
                <p class="h4 primary--text">Les parcours :</p>
                <p @click="checkAll(journeys)" class="text-decoration-underline cursor-pointer text-primary ml-2">Tout cocher</p>
                <p @click="uncheckAll(courses)" class="text-decoration-underline cursor-pointer ml-2 ">Tout décocher</p>
            </div>
            <label  v-for="course in journeys" :key="course._id">
                <input type="checkbox" :checked="isCourseChecked(course._id)" @change="checkCourse(course._id,$event.target.checked)"  >{{course.title}}
            </label>
        </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
    .courseChoice{
        display: flex;
        flex-direction: row;
    }
    label{
        text-align: left;
        display: flex;
        align-items: center;
        cursor: pointer;
        input{
            margin-right: 5px;
        }
    }
</style>

<script>

import { apiURL } from '@/../config'
import axios from 'axios'


export default {
  name: 'course-choice',
  data(){
      return {
          all:[],
          courses:[],
          journeys:[]
      }
  },
  props:['checked'],
  created: async function(){
    try {
      const res = await axios.get(apiURL + 'auth/courses', { params: { type: 'all' } })
      this.all = res.data.courses
      this.courses = res.data.courses.filter(el=>{return el.journey !== true})
      this.journeys = res.data.courses.filter(el=>{return el.journey === true})
    } catch (err) {
      this.$store.dispatch('open_dialog',{message:'Erreur concernant les formations',button:'Retour aux utilisateurs',cb:()=>{this.$router.push(`/admin/users`)}})
    }
  },
  methods:{
    checkAll: function(model){
        if(model) {
            const coursesId = model.map(e=>{return e._id})
            this.$emit('update:checked',this.checked.concat(coursesId))
        } else {
            const allCoursesId = this.all.map(e=>{return e._id})
            this.$emit('update:checked',allCoursesId)
        }
    },
    uncheckAll: function(model){
        if(model) {
            const coursesId = []
            for(const course of this.checked) {
                if(model.filter(c => c._id === course).length >= 1) coursesId.push(course)
            }
            this.$emit('update:checked',coursesId)
        } else this.$emit('update:checked',[])
    },
    isCourseChecked:function(courseId){
        return this.checked.includes(courseId)
    },
    checkCourse: function(courseId,val){
        let list = [...this.checked]
        if(val){
            list.push(courseId)
        }else{
            list = list.filter(e=>e!=courseId)
        }
        this.$emit('update:checked',list)
    }
  }
}
</script>
