<template>
  <div :class="['cta-input', author]">
      <v-btn :disabled="state === 'done'" :large="isMain" :outlined="!isMain" rounded color="primary" @click="handleClick()">{{text}}</v-btn>
  </div>
</template>

<script>
export default {
    name: 'cta-input',
    props: ['state','text','author'],
    data(){
        return {
        }
    },
    methods: {
        handleClick () {
            if (this.state !== 'done' ) this.$emit('done')
        }
    },
    computed : {
        isMain () {
            return this.author === 'main'
        }
    }
}
</script>

<style lang="scss">
    .cta-input{
        button{
            margin: 0;
        }
        &.main {
            .v-btn{
                background: $redGradient;
                transform: scale(1.2);
            }
        }
    }
</style>