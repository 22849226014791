<template>
    <div class="video-thumbnail" :style="`background-image: url('${thumbnail}')`" alt="">
    </div>
</template>

<script>

export default {
  name: 'video-thumbnail',
  components: {
  },
  data(){
    return {
      thumbnail:""
    }
  },
  props:['video'],
  async mounted(){
    this.thumbnail = await this.thumbnailSource()
  },    
  methods:{
    async thumbnailSource(){
      if(!this.video){return ''}
      if(this.video.type === "youtube"){
        return `https://img.youtube.com/vi/${this.video.id}/hqdefault.jpg`
      }else if(this.video.type === "vimeo"){
        const res = await fetch(`https://vimeo.com/api/v2/video/${this.video.id}.json`)
        const text = JSON.parse(await res.text())
        return text[0].thumbnail_large
      }else{
        return ""
      }
    }
  }
}
</script>

<style lang="scss">
.video-thumbnail{
  background-size: cover ;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
