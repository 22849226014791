<template>
    <div class="vimeo-embed" @click="playing = false">
        <v-progress-circular v-if="!loaded" indeterminate color="primary"></v-progress-circular>
        <v-icon v-if="button && loaded && !playing && !['sm', 'xs'].includes(this.$vuetify.breakpoint.name)" color="white" >mdi-play-circle-outline</v-icon>
        <iframe ref="video" :src="video+'?title=0&byline=0&portrait=0'" width="100%" height="100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    </div>
</template>

<script>
import Vimeo from "@vimeo/player";

export default {
  name: 'vimeo-embed',
  components: {
  },
  data(){
    return {
      player:null,
      playing: false,
      loaded: false
    };
  },
  props:['video','button'],
  mounted(){
    const v = this
    this.player = new Vimeo(this.$refs.video)
    this.player.on('ended', () => {
        v.$emit('end')
    })
    this.player.on('loaded', () => {
      v.loaded = true
    })
    this.player.on('play', () => {
      v.playing = true
      v.loaded = true
    })
    this.player.on('pause', () => {
      v.playing = false
    })
    this.player.on('bufferstart', () => {
      v.loaded = false
      v.playing = true
    })
  },    
  methods:{
      pause(){
          this.player.pause(true)
      }
  }
}
</script>

<style lang="scss">
.vimeo-embed{
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
    .v-icon, .v-progress-circular{
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 10rem;
      transform: translate(-50%,-50%);
      pointer-events: none;
      &:hover{
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
}
</style>
