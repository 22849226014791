<template>
    <div class="collectives-infos">
      <v-chip class="collectives-content mx-auto ma-md-2 ml-md-8" @click="$router.push(cb)" close @click:close="close()">
        {{content.message}}
      </v-chip>
    </div>
</template>

<script>
export default {
    name: 'collectives-infos',
    props:['content','cb','alt_cb'],
    methods: {
        close() {
            const now = new Date()
            localStorage.setItem('seen-collectives-infos', now)
            this.$store.dispatch('close_collectives_infos')
        }
    }
}
</script>

<style lang="scss">
.collectives-infos {
    position: relative;
    @include phone {
        display: flex;
        width: 100%;
        position: fixed;
        bottom: 5vh;
        z-index: 100000;
    }
    .collectives-content {
        background-color: $pastelYellow!important;
    }
}
</style>