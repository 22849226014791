<template>
  <div class="pa-2 BGwhitesmoke">
    <j-upload-image @change="changeImage" :thumbnail="image" btnText='Parcourir' placeholder="Image à upload"  elClass="left" :key="JSON.stringify(image)"></j-upload-image>
    <v-btn :disabled="!image" class="j-btn primary" @click="submit()">Upload</v-btn>
    <v-expand-transition>
      <div v-if='error' class="mt-5">
        <v-alert class="red lighten-2 white_">{{error}}</v-alert>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import axios from 'axios'
import { apiURL } from '@/../config'

export default {
  name: 'direct-upload',
  data(){
    return {
        image: null,
        error: ''
    }
  },
  methods: {
    changeImage: function(file){
      this.image = file
    },
    submit: async function() {
      this.$store.dispatch('loading')
      try {
        const formData = new FormData()
        formData.append("thumbnail",this.image)
        const res = await axios.post(apiURL+'auth/admin/upload/thumbnail',formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        this.$emit('directUpload', res.data)
      } catch (err) {
        this.error = err.message
      } finally {
        this.$store.dispatch('stop_loading')
      }
    }
  }
}
</script>
