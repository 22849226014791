<template>
    <div class="youtube-embed">
        <youtube :video-id="video.id" :ref="`video${video.id}`" @ready="ready" @ended="$emit('end')"></youtube>
    </div>
</template>

<script>
import Vue from 'vue';
import VueYouTubeEmbed from 'vue-youtube-embed';
Vue.use(VueYouTubeEmbed)

export default {
  name: 'youtube-embed',
  components: {
  },
  data(){
    return {
      player:null
    };
  },
  props:['video'],
  mounted(){
  },    
  methods:{
      pause(){
          this.player.pauseVideo()
      },
      ready(e){
        this.player = e.target
      }
  }
}
</script>

<style lang="scss">
.youtube-embed{
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}
</style>
