<template>
<div class="engage-content">
    <profile-bubble :username="''" image="/images/coach-sample.png"></profile-bubble>
    <div class="engage-content__actions">
        <p>{{ title }}</p>
        <span v-for="( c, index ) in content " :key="index" class="wi-100">
          <div>
              <p v-html="c.text"></p>
              <v-btn @click="handleClick(c)" v-if="c.button" large rounded :class="{ 'py-8' : c.button.big  }"  :outlined="!c.button.big" color="white"><v-icon v-if="c.button.icon" class="mr-2">{{c.button.icon}}</v-icon>{{c.button.text}}</v-btn>
          </div>
        </span>
    </div>
</div>
</template>

<script>
import ProfileBubble from '../chat/ProfileBubble.vue'
export default {
    name: 'engage-content',
    components : { ProfileBubble },
    props : ['title', 'content'],
    methods : {
        handleClick (c) {
            if ( typeof c.button.cb === 'function' ) c.button.cb()
            else console.log('No CB') 
        }
    }
}
</script>

<style lang="scss">
    .engage-content{
      background: $greenGradient;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-radius: 10px;
      @include boxShadow(rgba(black, 0.1));
      padding: 2rem;
      @include noPhone{
        padding-top: 3rem!important;
        padding-bottom: 3rem!important;
      }
      @include phone {
        flex-direction: column;
      }
      .profile-bubble{
        $profileBubbleW : 15rem;
        height: $profileBubbleW;
        width: $profileBubbleW;
        margin-right: 3rem;
        @include phone{
          margin-right: 0;
          margin-bottom: 1rem;
        }
      }
      &__actions{
        >p{
          color: white;
          font-weight: 900;
          font-size: 2.5em;
          width: 100%;
          text-align: left;
          margin-bottom: 2rem;
          line-height: 1;
          @include phone {
            text-align: center;
          }
        }
        >span{
          display: block;
          +span{
            margin-top: 2rem;
          }
          >div{
            display: flex;
            align-items: center;
            width: 100%;
            @include phone{
              flex-direction: column;
            }
            >p{
              width: 60%;
              flex-basis: 60%;
              flex-grow: 0;
              text-align: left;
              color: white;
              margin-right: 2rem;
              margin-bottom: 0;
              @include phone{
                width: 100%;
                margin-right: 0;
                margin-bottom: 1rem;
              }
            }
            .v-btn{
              flex-grow: 1;
              border-radius: 100px;
              @include phone {
                width: 100%;
              }
            }
          }
        }
      }
    }
</style>