<template>
    <v-card class="select-popup pa-5 rounded-xl">
        <v-card-title class="d-flex flex-wrap justify-space-between align-center px-0">
            <div class="wi-100 d-flex align-center">
                <p class="ma-0">{{title}}</p>
                <v-btn rounded outlined color="primary" class="my-5 ml-auto" @click="done()" :disabled="!showValidate">Confirmer</v-btn>
            </div>
            <v-expand-transition>
                <span v-if="!other" class="wi-100">
                    <v-text-field 
                        v-model="search"
                        hide-details 
                        outlined 
                        clearable
                        prepend-inner-icon="mdi-magnify" 
                        :label="`Recherchez ${placeholder.toLowerCase()}`" 
                        @click:clear="selected = ''"
                        ref="search"
                    ></v-text-field>
                </span>
            </v-expand-transition>
        </v-card-title>
        <div v-if="enableOther && (other || search && !cachedItems.length)" class="d-flex wi-100 text-left pl-5 mb-2 align-center">
            <input :checked="other" class="mr-2" :id="`${selectID}selectOther`" name="myJob" type="radio" value="Other" @click="other= true; search = ''" >
            <label class="cursor-pointer" :for="`${selectID}selectOther`">Autre</label>
            <div class="d-flex flex-grow-1 ml-5" v-if="other">
                <v-text-field class="mt-0 mr-5" @keydown="handleInput($event)" dense hide-details prepend-inner-icon="mdi-briefcase" :label="otherplaceholder" v-model="custom"></v-text-field>
                <v-icon class="flex-shrink-0" @click.stop="closeCustom()">mdi-close</v-icon>
            </div>
        </div>
        <span class="wi-100" v-if="!other">
            <div v-for="(item, index) in cachedItems" :key="index" class="pl-5 mb-2 d-flex wi-100 text-left align-center">
                <input class="mr-2" :id="`mySelectionInput${selectID}${index}`" name="mySelection" type="radio" :value="item[itemText]" @click="selected = item">
                <label class="cursor-pointer mb-0" :for="`mySelectionInput${selectID}${index}`">{{item[itemText]}}</label>
            </div>
        </span>
        <div class="d-flex justify-end">
            <v-btn rounded outlined color="primary" class="my-5" @click="done()" :disabled="!showValidate">Confirmer</v-btn>
        </div> 
    </v-card>
</template>

<script>
export default {
    name: 'select-popup',
    props: ['items', 'title', 'enableOther', 'otherplaceholder', 'placeholder','itemText', 'itemValue'],
    data () {
        return {
            selected: '',
            search : '',
            other: false,
            custom: ''
        }
    },
    methods : {
        done () {
            if (this.other && this.custom || this.selected) {
                this.$emit('done', !this.other ? this.selected : this.custom )
            } 
        },
        closeCustom () {
            this.other = false
            this.custom = ""
        },
        handleInput (e) {
            if(e.code === "Enter" && this.state !== 'done') this.done()
        }
    },
    computed : {
        cachedItems () {
            if (!this.search.length) return this.items
            else return this.items.filter( e => { return e[this.itemText].toLowerCase().includes(this.search.toLowerCase()) } )
        },
        showValidate () {
            return this.other && this.custom.length > 2 || this.selected !== ''
        },
        selectID () {
            return this.title ? this.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim() : 'mySelect';
        }
    }
}
</script>

<style lang="scss">
    .select-popup{
        height: 70vh;
        overflow: auto;
        .v-input--radio-group{
            margin-top: 0;
            .v-messages{
                display: none;
            }
        }
    }
</style>