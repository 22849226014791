<template>
    <div class="login-dialog d-flex justify-center align-center">
            <i class="closeIcon" @click="$store.dispatch('close_login_dialog')"></i>
            <v-card class="wi-70 wi-sm-90 rounded-lg overflow-hidden" >
                <v-container class="pa-0">
                    <v-row >
                        <v-col class="pa-0 pb-2 form-col d-flex flex-column" sm="12" md='6'>
                            <div class="pt-7 pb-0 px-10 ">
                                <p class="text-uppercase h4 mb-0">Une dernière étape...</p>
                                <div class="wi-100">
                                    <p class="mb-0">Pour nous permettre de calculer vos résultats vous devez vous {{login ? "connecter" : "inscrire"}}.</p>
                                    <p @click="login = !login" class=" mt-2 caption  secondary--text cursor-pointer text-decoration-underline">{{login ? "Je n'ai pas de" : "J'ai déjà un"}} compte Jobtimise</p>
                                </div>
                            </div>
                            <div class="pt-0 px-10 flex-grow-1 d-flex flex-column justify-center">
                                <p v-if="login">Connexion à votre compte Jobtimise :</p>
                                <j-register-form v-if="!login" @registered="$store.dispatch('close_login_dialog')" ></j-register-form>
                                <j-login-form v-else @logged="$store.dispatch('close_login_dialog')"></j-login-form>
                            </div>
                        </v-col>
                        <v-col class="align-stretch BGblueGradient hidden-sm-and-down" cols="6" >
                            <div class="he-100 d-flex justify-center flex-column">
                                <img class="wi-100" src="/images/login.png" alt="Connexion">
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'login-dialog-box',
  props:[],
  data(){
      return {
          login:false
      }
  },
  mounted(){
      
  },
  methods:{
      callback:function(){
          if(this.cb){
              this.cb()
          }
          this.$store.dispatch('close_login')
      }
  }
}
</script>

<style lang="scss" scoped>
    .login-dialog{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(black,0.5);
        z-index: 10;
        .form-col{
            @include noPhone{
                min-height: 80vh;
            }
        }
        @include closeIcon(white, 2em, 0.2em,2em,2em)
    }
</style>
