import Vue from 'vue'

let state = {
  pending: null
}


const actions = {
  set_pending : ( { commit }, page ) => {
    commit('set_pending', page)
  },
  reset_pending: ({ commit }) => {
    commit('reset_pending')
  }
}

const mutations = {
    set_pending: (state, page) => {
        Vue.set(state,"pending", page)
    },
    reset_pending: state=>{
        Vue.set(state, "pending", null)
    }
}

export default {
  state,
  actions,
  mutations
}
  