<template>
  <div class="file-input">
    <div class="loader d-flex justify-center align-center px-5" v-if="loading.nonce || loading.upload">
      <v-progress-circular
            v-if="loading.nonce || loading.upload && progress === 100"
            size="30"
            indeterminate
            color="primary"
      ></v-progress-circular>
      <v-progress-linear v-if="loading.upload && progress < 100" :value="progress"></v-progress-linear>
    </div>
    <span class="d-flex wi-100" v-if="simpleInput || !['xs','ms'].includes($vuetify.breakpoint.name)"  >
      <v-file-input 
        ref="fileInput"
        :accept="content.accept" 
        v-model="input" 
        :rules="rules"
        dense 
        truncate-length="15" 
        :disabled="state === 'done' || loading.nonce || loading.upload"
        :placeholder="content.placeholder"
        @update:error="error = $event"
        @change="upload()"
        :rounded="!!rounded"
        :outlined="!!outlined"
      >
      </v-file-input>
      <v-icon v-if="checkFile && !loading && state !== 'done'" @click="upload()" color="primary" class="ml-5" >mdi-send-circle</v-icon>
    </span>
    <p v-else >...</p>
  </div>
</template>

<script>
import { apiURL } from '@/../config'
import axios from 'axios'

export default {
    name: 'file-upload',
    props: ['content', 'state', 'optional', 'simpleInput', 'rounded', 'outlined'],
    data(){
      return {
        input: null,
        nonce : null,
        progress: 0,
        error : false,
        uploadedLink : '',
        loading : {
          nonce : true,
          upload : false
        }
      }
    },
    async created () {
      try {
        const recaptcha = await this.$recaptcha('login')
        const res = await axios.post(`${apiURL}public/usage-nonce`,{ usage : 'uploadCV', recaptcha })
        this.nonce = res.data.nonce
        this.loading.nonce = false
      } catch (e) {
        console.log(e)
      }
    },
    mounted () {
      if (['sm', 'xs'].includes(this.$vuetify.breakpoint.name) && !this.simpleInput) this.$store.dispatch('display_message_input',{ inputType: 'file', inputName: this.content.inputName, fileTypes: this.content.fileTypes, accept: this.content.accept, inputValidation:  this.checkRules, placeholder: this.content.placeholder, cb: this.send, optional: this.optional })
    },
    computed : {
      rules () {
        return [
            value => !value || value.size < 10000000 || 'Ne dépassez pas 10 MB svp',
            value => !value || this.content.fileTypes.includes(value.name.split('.').pop())|| 'Merci de choisir le format voulu'
        ]
      },
      checkFile () {
        return !!this.input && this.content.fileTypes.includes(this.input.name.split('.').pop())
      }
    },
    methods : {
      async send (payload) {
        this.$store.dispatch('hide_message_input')
        this.$emit('done',payload)
      },
      done () {
        const payload = {}
        if (this.uploadedLink || this.optional) {
          payload[this.content.inputName] = this.uploadedLink
          this.$emit('done',payload)
        }
      },
      checkRules (input) {
        return this.rules.reduce( (total, current) => { 
          const test = current(input)
          return total && (typeof test === 'boolean' ? test : false)
        }, true)
      },
      async upload () {
        const rules = this.checkRules(this.input)
        if (!this.input || !rules) return false 
        try{
            this.loading.upload = true
            const recaptcha = await this.$recaptcha('login')
            const formData = new FormData()
            formData.append("file",this.input)
            const axiosConfig = {
              onUploadProgress: (progressEvent) => {
                this.progress = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
              },
              headers: { 'Content-Type': 'multipart/form-data', 'Nonce' : this.nonce, 'Recaptcha' : recaptcha }
            }
            const inputUrl =  await axios.post(`${apiURL}public/upload`,formData, axiosConfig)
            this.uploadedLink = inputUrl.data
            return this.done()
        }catch(e){
            console.log(e)
            this.input = null
            this.$store.dispatch('open_dialog',{message: "Une erreur est survenue"})
        }
        finally{
            this.loading.upload = false
        }
      }
    }
}
</script>

<style lang="scss">
.file-input{
  display: flex;
  position: relative;
  .loader{
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(white, 0.9);
    z-index: 1;
  }
  .v-file-input{
    width: 13rem;
  }
}
</style>