<template>
  <div :class="['ranking-input', state]">
    <p class="caption mb-5" v-if="content.explanation" v-html="content.explanation"></p>
    <draggable  @start="drag = true" v-bind="dragOptions" @end="drag = false" :value="list" @change="handleChange($event)"  ghost-class="ghost">
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
            <v-list-item v-for="(item) in list" class="box-shadow-1 my-2 rounded-lg py-1 py-md-2 px-md-5   " :key="item._id">
                <v-list-item-title>{{item.title[0].toUpperCase() + item.title.slice(1)}}</v-list-item-title>
                <v-btn icon color="primary">
                    <v-icon>mdi-arrow-up-down-bold</v-icon>
                </v-btn>
            </v-list-item>
        </transition-group>
    </draggable>
    <v-expand-transition>
        <span v-if="state !== 'done'">
            <v-btn  class="wi-100 mt-5" dense rounded outlined color="primary" @click="validate()">Valider</v-btn>
        </span>
    </v-expand-transition>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
    name: "ranking-input",
    components: { draggable },
    props: ['state', 'content'],
    data () {
        return {
            drag: false,
            list: []
        }
    },
    created () {
        this.list = this.content.items
    },
    methods: {
        handleChange (e) {
            const {moved} = e;
            const el = this.list[moved.oldIndex];
            this.list.splice(moved.oldIndex,1);
            this.list.splice(moved.newIndex,0,el);
        },
        validate () {
            this.$emit('done',{ rankedQualities: this.list.map( e => { return e._id }) })
        }
    },
    computed: {
        dragOptions () {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            }
        }
    }
}
</script>

<style lang="scss">
    .ranking-input{
        @include noPhone{
            max-width: 85%;
        }
        @include phone {
            width: 100%;
        }
        &.done{
            opacity: 0.4;
            pointer-events: none;
        }
        .v-list-item{
            min-height: 0;
            background: white;
            &__title{
                    text-transform: capitalize;  
                    @include phone {
                        font-size: 0.8rem;
                    }
            }
        }
        .ghost {
            opacity: 0.5;
            background: rgba($secondary,0.5);
        }
    }
</style>