<template>
  <div class="select-input">
        <span v-if="!['xs','ms'].includes($vuetify.breakpoint.name) || state === 'done'" @click="dialog = true && content.usePopup">
            <v-select 
                v-if="state !== 'done'" 
                :readonly="content.usePopup"
                :items="content.items" solo rounded v-model="selected" 
                :label="content.label"  :disabled="state === 'done'"
                :item-text="content.itemText"
                :return-object="content.returnObject"
                @change="validate()"
            ></v-select>
            <v-text-field v-else disabled hide-details dense :value="finalValue"></v-text-field>
        </span>
        <p v-else>...</p>
        <v-dialog v-if="content.usePopup && state !== 'done'" :width="['sm', 'xs'].includes(this.$vuetify.breakpoint.name) ? '90vw':'50vw'" v-model="dialog" persistent>
            <select-popup @done="handleDone($event)" :items="content.items" :title="content.label" :otherplaceholder="content.label" :placeholder="content.label" :item-text="content.itemText" :item-value="content.itemValue" :enableOther="content.enableOther"></select-popup>
        </v-dialog>
  </div>
</template>

<script>
import SelectPopup from './SelectPopup.vue'
export default {
    name: 'select-input',
    props: ['state', 'content'],
    components: {SelectPopup},
    data(){
        return {
            selected:null,
            dialog : false
        }
    },
    mounted () {
      if (['sm', 'xs'].includes(this.$vuetify.breakpoint.name)) this.$store.dispatch('display_message_input',{label: this.content.label, inputType: 'select', items: this.content.items, cb: this.send, inputValidation: this.testInput, usePopup: this.content.usePopup, itemValue: this.content.itemValue, enableOther: this.content.enableOther, itemText: this.content.itemText, returnObject: this.content.returnObject })
    },
    methods: {
        validate () {
            if (this.state !== 'done' ){
                const payload = {}
                let toReturn 
                if( this.returnObject && typeof this.selected !== 'object' && this.content.itemValue && this.content.itemText) {
                    toReturn = {}
                    toReturn[this.content.itemValue] = this.selected
                    toReturn[this.content.itemText] = this.selected
                    this.selected = toReturn
                }
                else if ( !this.returnObject && typeof this.selected === 'object' && this.content.itemValue) {
                    toReturn = this.selected[this.content.itemValue]
                }
                else {
                    toReturn = this.selected
                }
                payload[this.content.inputName] = toReturn
                this.$emit('done', payload)
            }
        },
        send (v) {
            this.selected = v
            this.validate()
            this.$store.dispatch('hide_message_input')
        },
        testInput (value) {
            return !!value && value.length > 0
        },
        handleDone (val) {
            this.selected = val
            this.dialog = false
            this.validate()
        }
    },
    computed: {
        finalValue () {
            if (typeof this.selected !== 'object') return this.selected
            else if(this.content.itemText) return this.selected[this.content.itemText]
            else return '-'
        }
    }
}
</script>

<style lang="scss">
    .select-input{
        .v-input{
            width: 15rem;
        }
    }
    .v-dialog{
        @include whiteScrollbar()
    }
</style>