<template>
    <div id="popup">
        <div class="box rounded-b-xl">
            <p class="pa-3 ma-0">{{content.message}}</p>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'pop-up',
  props:['content','cb','alt_cb'],
  mounted(){
		setTimeout(this.callback, 4000)
  },
  methods:{
      handleEnter: function(e){
          if(e.code == "Enter"){
              this.callback()
          }
      },
      callback:function(){
          if(this.cb){
              this.cb()
          }
          this.$store.dispatch('close_popup')
      },
      altCallback:function(){
          if(this.altCb){
              this.altCb()
          }
          this.$store.dispatch('close_popup')
      }
  }
}
</script>

<style lang="scss">
#popup{
    .box{
        position:absolute;
        left:0;
        right:0;
        margin-left:auto;
        margin-right:auto;
        top: 0;
        z-index: 1000000;
        text-align: center;
        background: whitesmoke;
        box-shadow: 0 0 5px 5px rgba(0,0,0,0.1);
        width: 20%;
            @include phone {
                width: 80%;
            }
    }
}
</style>
