<template>
    <div class="banner-infos primary d-flex flex-column">
      <div class="banner-scroll">
        <div class="banner-scroll__title">
          <div><p><span v-for="i in 20" :key="i">promo Jobtimise - </span></p></div>
        </div>
      </div>
      <div class="banner-content d-flex flex-column flex-md-row justify-center pa-4">
    <i class="closeIcon" @click="$store.dispatch('close_banner_infos')"></i>
        <div class="d-flex align-center ma-0 mr-md-2">
            <p class="wi-100 white_ h4 ma-md-0 text-center text-md-left">Des promotions incroyables ! </p>
        </div>
        <div class="d-flex align-center">
            <v-btn class="j-btn">Cliquer ici</v-btn>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'banner-infos',
    props:['content','cb','alt_cb']
}
</script>

<style lang="scss">
.banner-infos {
  position: fixed; 
  bottom:0%;
  z-index: 1000000;
  width: 100vw!important;
  height: 15vh;
  @include phone {
    height: 25vh;
  }
  .banner-content {
    position: relative;
    @include closeIcon(white, 2em, 0.2em,0.5em,0em);
    .closeIcon {
        left: 0.5em!important;
    }
  }
    .banner-scroll {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;
      margin: auto;
      background-color: #D34444;
      overflow: hidden;
      z-index: 1;
      &__title {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        white-space: nowrap;
        transition: all 1s ease;
        @include phone {
            transform: scale(2);
        }
          & > div {
            display: flex;
            animation: scrollText 33s infinite linear;
          }
          p {
            text-transform: uppercase;
            margin: 0;
            color: rgba(255,255,255,1);
            transition: all 2s ease;
          }
      }
    }
//   div:hover {
//     animation-play-state: paused;
//   }
  @keyframes scrollText {
    from   { transform: translateX(0%); }
    to { transform: translateX(-50%); }
  }
}
</style>