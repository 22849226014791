<template>
  <div class="typing-bubble">
      <div>
        <span v-for="i in 3" :key="i" :class="{ active: active === i }"></span>
      </div>
  </div>
</template>

<script>
export default {
    name: 'typing-bubble',
    data () {
        return {
            active: 1
        }
    },
    created () {
        setInterval( () => {
            this.active = this.active === 3 ? 1 : this.active + 1 
        },300)
    }
}
</script>

<style lang="scss">
    .typing-bubble{
        padding: 1.5em;
        @include phone{
            padding-top: 0.5em;
            padding-bottom: 1.5em;
            padding-left: 1em;
        }
        >div{
            background: rgba(white,0.4);
            padding: 0.5rem 0.3rem;
            display: flex;
            border-radius: 5rem;
            height: fit-content;
            span{
                width: 0.6rem;
                height: 0.6rem;
                border-radius: 100%;
                background: rgba(white, 0.6);
                margin: 0 0.2rem;
                transition: all linear 0.5s;
    
                &.active{
                    transform: translate(0, -0.3em);
                    background: rgba(white, 1);
                }
            }
        }
    }
</style>