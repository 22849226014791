<template>
    <div :class="['message-input', { optional : $store.state.messageInput.optional } ]">
        <v-text-field 
            v-if="$store.state.messageInput.content.inputType === 'input'" 
            class="flex-grow-1"
            v-model="message" 
            ref="message" :type="$store.state.messageInput.content.type" 
            :placeholder="$store.state.messageInput.content.placeholder" 
            outlined 
            rounded 
            dark 
            dense
        ></v-text-field>
        <span class="d-flex flex-grow-1" @click="dialog = true" v-if="$store.state.messageInput.content.inputType === 'select'">
            <v-select  
                v-model="message" 
                :readonly="$store.state.messageInput.content.usePopup"
                :items="$store.state.messageInput.content.items" 
                :item-value="$store.state.messageInput.content.itemValue"
                :item-text="$store.state.messageInput.content.itemText"
                :label="$store.state.messageInput.content.label"  
                :return-object="$store.state.messageInput.content.returnObject"
                solo 
                rounded 
                @change="send()"
            ></v-select>
        </span>
        <span class="d-flex flex-grow-1" v-if="$store.state.messageInput.content.inputType === 'file'">
            <file-upload class="wi-100" @done="handleDone($event)" :state="''" :rounded="true" :outlined="true" :simpleInput="true" :content="$store.state.messageInput.content"></file-upload>
        </span>
        <v-btn @click="send()" fab color="secondary" v-if="!loading && $store.state.messageInput.content.inputType != 'file'" small :disabled="!$store.state.messageInput.inputValidation(message)">
            <v-icon color="white">mdi-send</v-icon>
        </v-btn>
        <v-progress-circular v-if="loading && $store.state.messageInput.content.inputType != 'file'" indeterminate color="primary"></v-progress-circular>
        <v-dialog v-if="$store.state.messageInput.usePopup" :width="['sm', 'xs'].includes(this.$vuetify.breakpoint.name) ? '90vw':'50vw'" v-model="dialog" persistent>
            <select-popup @done="handleDone($event)" :title="$store.state.messageInput.content.label" :items="$store.state.messageInput.content.items" :item-text="$store.state.messageInput.content.itemText" :item-value="$store.state.messageInput.content.itemValue" :placeholder="$store.state.messageInput.content.label" :otherplaceholder="$store.state.messageInput.content.label" :enableOther="this.$store.state.messageInput.content.enableOther"></select-popup>
        </v-dialog>
        <p class="skip" @click="skip()" v-if="$store.state.messageInput.optional">Passer cette étape</p>
    </div>
</template>

<script>
import SelectPopup from './SelectPopup.vue'
import FileUpload from './FileUpload.vue'
export default {
    name: 'message-input',
    components: { SelectPopup, FileUpload },
    data () {
        return {
            message: null,
            dialog: false,
            loading: false
        }
    },
    mounted () {
    },
    methods: {
        async send () {
            try{
                this.loading = true
                await this.$store.state.messageInput.cb(this.message)
                this.message = ''
                this.loading = false
            } catch (e) {
                console.log(e)
                alert('Une erreur est survenue, veuillez réessayer svp')
            }
        },
        skip () {
            this.message = null
            this.send()
        },
        handleDone (val) {
            this.message = val
            this.send()
        }
    },
    computed: {
    }
}
</script>

<style lang="scss">
.message-input{
    animation: inBottom 0.3s ease;
    animation-fill-mode: forwards;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background: white;
    display: flex;
    align-items: center ;
    padding: 1rem 0.5rem;
    &.optional{
        padding-bottom: 3rem;
    }
    .skip{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        color: $grey;
        text-decoration: underline;
    }
    .v-text-field{
        margin-right: 1rem;
        font-weight: 300;
        .v-input__slot{
            display: flex;
            align-items: center;
            justify-content: center;
            .v-input__prepend-inner{
                margin: auto;
                height: auto;
                p{
                    color: $textGrey;
                    height: auto;
                    margin: 0 0.5rem 0 0;
                    font-size: 0.8rem;
                }
            }
        }
        .v-input__slot{
            background: rgba(white, 1);
            border-color: $primary!important;
            margin-bottom: 0;
            box-shadow: 0px 0px 7px 0 rgba($textGrey,0.2);
            input{
                color: black;
                caret-color:$secondary;
                &::placeholder{
                  color: $grey;  
                }
            }
        }
        .v-text-field__details{
            display: none;
        }
    }
}
</style>